import React from 'react'
// import LandingNav from '../../components/user/LandingNav'
import LandingHeader from '../../components/user/LandingHeader'
import LandingLocation from '../../components/user/LandingLocation'
import LandingPrice from '../../components/user/LandingPrice'
// import OurService from '../../components/user/OurService'
import OurWork from '../../components/user/OurWork'
import Testimonial from '../../components/user/Testimonial'
import LandingFooter from '../../components/user/LandingFooter'
import ButtonComponent from '../../components/user/ButtonComponent'
// import KeyWords from '../../components/user/KeyWords'
import ClientSaysComponent from '../../components/user/ClientSaysComponent'
import { Helmet } from 'react-helmet';

const LandingPage = () => {
  return (
    <div>

      
<Helmet>
        <title>Garage on call offers Bike servicing at home in Noida and Delhi</title>
        <meta name="description" content="
        Garage on call - We offers Bike servicing at home in Noida, Delhi, Gurgaon and Ghaziabad. Call us for doorstep bike servicing and repairing +91 9658124124
        " />
      </Helmet>
      
      
      <div className="landing-page">

        <LandingHeader/>
        <ClientSaysComponent/>
        <LandingLocation/>
        <LandingPrice/>
        {/* <OurService/> */}

        <OurWork/>

        <Testimonial/>
        {/* <KeyWords/> */}
 
        <LandingFooter/>

        <ButtonComponent/>
        
        {/* <div className="landing-page-header">
          <LandingNav/>
        </div> */}
      </div>
    </div>
  )
}

export default LandingPage