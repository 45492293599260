import React from 'react'
// import LandingNav from '../../components/user/LandingNav'
import LandingHeader from '../../components/user/LandingHeader'
import LandingLocation from '../../components/user/LandingLocation'
import OurWork from '../../components/user/OurWork'
import Testimonial from '../../components/user/Testimonial'
import LandingFooter from '../../components/user/LandingFooter'
import ButtonComponent from '../../components/user/ButtonComponent'
import BengaluruLandingPrice from '../../components/user/BengaluruLandingPrice'
import ClientSaysComponent from '../../components/user/ClientSaysComponent'
import { Helmet } from 'react-helmet';


const BengaluruLandingPage = () => {
  return (
    <div>


      
<Helmet>
        <title>Garage on call offers Bike servicing at home in Bengaluru</title>
        <meta name="description" content="
        Garage on call - We offers Bike servicing at home in Bengaluru. Call us for doorstep bike servicing and repairing +91 9658124124. Book service now.
        " />
      </Helmet>
      

<div className="landing-page">

<LandingHeader/>
<ClientSaysComponent/>

<LandingLocation/>
<BengaluruLandingPrice/>
{/* <OurService/> */}

<OurWork/>

<Testimonial/>

<LandingFooter/>

<ButtonComponent/>

{/* <div className="landing-page-header">
  <LandingNav/>
</div> */}
</div>
        
        
    </div>
  )
}

export default BengaluruLandingPage