import React from "react";
import google from "../../media/images/Google.png"
import facebook from "../../media/images/FaceBook.png"
import justdial from "../../media/images/JustDial.png"
import { Link } from "react-router-dom";

const ClientSaysComponent = () => {
  return (
    <div>
      <div className="client-say">
      <div className="client-say-heading">What Our <span>Clients</span> Say<span>?</span></div>

      <div className="client-say-content">
        <div className="client-say-col">
          <div className="client-say-col-img"><img src={google} alt="social media" title="social media" /></div>
          <div className="client-say-col-rating">
            <span>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
            </span>
            &nbsp; 4.7/5 Rating
          </div>
          <div className="client-say-col-link"><Link title="social link" target="_blank" to='https://g.co/kgs/NH33KRD'>view on Google</Link></div>
        </div>
        

        <div className="client-say-col">
          <div className="client-say-col-img"><img src={facebook} alt="social media" title="social media" /></div>
          <div className="client-say-col-rating">
            <span>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star-half-stroke"></i>
            </span>
            &nbsp; 4.8/5 Rating
          </div>
          <div className="client-say-col-link"><Link title="social link" target="_blank" to='https://www.facebook.com/garageoncallofficial/'>view on FaceBook</Link></div>
        </div>

        <div className="client-say-col">
          <div className="client-say-col-img"><img src={justdial} alt="social media" title="social media" /></div>
          <div className="client-say-col-rating">
            <span>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
            </span>
            &nbsp; 4.7/5 Rating
          </div>
          <div className="client-say-col-link"><Link title="social link" target="_blank" to='https://jsdl.in/DT-231WDUMJD1P'>view on JustDial</Link></div>
        </div>

      </div>
      </div>
    </div>
  );
};

export default ClientSaysComponent;
