import React from 'react'
import { Link } from 'react-router-dom';

const ButtonComponent = () => {

    return (
        <div>
            <div>


            </div>

            <div className='side-fix-buttons'>
                <div className='side-fix-heading'>FOLLOW US</div>
                <div className='side-fix-buttons-icon'><br />
                    <div className='side-fix-b-icon'><Link title="contact link" to="https://www.youtube.com/@GarageOnCall" target='blank'><i className="fa-brands fa-youtube"></i></Link></div>
                    <div className='side-fix-b-icon'><Link title="contact link" to="https://www.facebook.com/garageoncallofficial/" target='blank'><i className="fa-brands fa-square-facebook"></i></Link></div>
                    <div className='side-fix-b-icon'><Link title="contact link" to="https://www.instagram.com/garageoncallofficial/" target='blank'><i className="fa-brands fa-instagram"></i></Link></div>
                    <div className='side-fix-b-icon'><Link title="contact link" to="https://www.linkedin.com/company/garage-on-call/" target='blank'><i className="fa-brands fa-linkedin"></i></Link></div>
                    <div className='side-fix-b-icon'><Link title="contact link" to="https://twitter.com/i/flow/login?redirect_after_login=%2FGarageoncall" target='blank'><i className="fa-brands fa-square-x-twitter"></i></Link></div>

                </div>


            </div>
            <div className='contact-fix-btn'>
                <div className='call-fix-buttons'><Link title="contact link" to="tel:9658124124" target='blank'><i className="fa-solid fa-phone-volume"></i></Link></div>
                <div className='whatsapp-fix-buttons'><Link title="contact link" to="https://wa.me/+919658124124?text=Hello ! I'm Interested in Doorstep Bike Service. Please contact me." target='_blank'><i className="fa-brands fa-whatsapp"></i></Link></div>
                
            </div>


        </div>
    )
}

export default ButtonComponent