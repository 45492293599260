import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CountUp from "react-countup";

import bikeparts from "../../media/images/abc.png";
import mechanic from "../../media/images/mechanic.png";
import LoadingComponent from "./LoadingComponent";


import logo from "../../media/images/logo.png"


const LandingHeader = () => {
 
  const [brandOptions, setBrandOptions] = useState(false);
  
    const [showModels, setShowModels] = useState(false)

  // fetch ---------------------------------------------------------------------------

  const [brand, setBrand] = useState([]);
  const [brandName, setBrandName] = useState("--- select Brand ---");
  
  const [model, setModel] = useState([]);

  const loadData = async () => {
    let res = await fetch(`https://api.garageoncall.com/bikeApi`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    res = await res.json();
    console.log("res", res.data);
    setBrand(res.data);
  };

  useEffect(() => {
    loadData();
  }, []);

  // console.log("model = ", model)

  

  //   --------------------------- submit form -----------------------------

  const [loading, setLoading] = useState(false);

  const [user, setUser] = useState({
    name: "",
    bike: "",
    city: "",
    phone: "",
    user: "true",
    status: "true",
  });

  const handleChange = (e) => {
    const value = e.target.value;
    setUser({
      ...user,
      [e.target.name]: value,
    });
  };
  const navigate = useNavigate()

  const handleSubmit = async (e) => {
    setLoading(true);

    e.preventDefault();
    try {
      const response = await fetch("https://api.garageoncall.com/booking-form", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      });

      if (response.ok) {
        // setBookingForm(false);
        alert("Service booked successfully");
        navigate("/booking-confirm");

      } else {
        const res_data = await response.json();
        alert(res_data.message);
      }

    } catch (error) {
      alert("server error");
    }
    setLoading(false);
  };

  return (
    <div>
      {loading ? (
        <div className="data-loading">
          <LoadingComponent />
        </div>
      ) : (
        ""
      )}

      <div className="landing-page-header">


        {/* ----------- col-2 ------------ */}
        <div className="landing-page-nav-main">
        <div className="landing-page-nav">

          <div className="nav-logo"><img title="logo" src={logo} alt="logo" /></div>
          <div></div>
          <div className="landing-page-nav-buttons">
            <Link title="contact" to="tel:01204225828">CALL NOW</Link>
            <a title="form link"  href="#landingForm">BOOK NOW</a>
          </div>
        </div>
        </div>

        {/* ----------- col-3 ------------ */}
        <div className="landing-page-header-main">
        <div className="landing-page-header-content">
          {/* ---------- lp-c-col-1 ---------- */}
          <div className="landing-page-header-content-col-1">
            <div className="landing-page-header-content-col-1-img">
              <img title="bike service parts" src={bikeparts} alt="bike parts" />
            </div>
            <div className="landing-page-header-content-col-1-heading">
              People trust Garage On Call
            </div>
            <div className="landing-page-header-content-col-1-stars">
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star-half-stroke"></i>
            </div>
            <div className="landing-page-header-content-col-1-numbers">
              <div className="landing-page-header-content-col-1-numbers-col-1">
                <div className="landing-page-header-content-col-1-numbers-n">
                  <CountUp end={4} duration="4" enableScrollSpy />.
                  <CountUp end={7} duration="4" enableScrollSpy />
                  /5
                </div>
                <div className="landing-page-header-content-col-1-numbers-txt">
                  1,00,000+
                  <br />
                  reviews
                </div>
              </div>

              <div className="landing-page-header-content-col-1-numbers-col-2">
                <div className="landing-page-header-content-col-1-numbers-n">
                  <CountUp end={85000} duration="4" enableScrollSpy />+
                </div>
                <div className="landing-page-header-content-col-1-numbers-txt">
                  Happy
                  <br />
                  Customers
                </div>
              </div>

              <div className="landing-page-header-content-col-1-numbers-col-3">
                <div className="landing-page-header-content-col-1-numbers-n">
                  <CountUp end={11} duration="4" enableScrollSpy />+
                </div>
                <div className="landing-page-header-content-col-1-numbers-txt">
                  Years Of <br />
                  experience
                </div>
              </div>
            </div>
          </div>

          {/* ---------- lp-c-col-2 ---------- */}
          <div id="landingForm" className="landing-page-header-content-col-2">
            <div className="landing-page-header-content-col-2-form">
              <h1 className="landing-page-header-content-col-2-form-heading">
                <img title="mechanic" src={mechanic} alt="mechanic" />
                <span>
                  Best <span>Bike Service</span> <br />
                  At Your Home
                </span>
              </h1>
              <form onSubmit={handleSubmit}>
                <input type="text" placeholder="Enter Your Name*"
                required
                name="name"
                onChange={handleChange}
                />
              <div className='sb-c3-1-col'>
                
              <div className="select-brand" onClick={e=>{setBrandOptions(!brandOptions)}}><span>{brandName}</span>  <span>{brandOptions === true?<i class="fa-solid fa-angle-up"></i>:<i class="fa-solid fa-angle-down"></i>}</span></div>
                    {
                      brandOptions === true?
                    <div className='select-brand-options'>
                      {
                        brand.map(e => {
                          return (
                            <div onClick={() => { setModel(e.model); setBrandName(e.brand); setBrandOptions(false); setShowModels(true) }}>{e.brand}</div>
                            )
                          })
                      }
                    </div>
                    :""
                  }
                  </div>
                <select
                  className="select-bike-inp"
                  name="bike"
                  required
                  onChange={handleChange}
                  >
                  <option disabled selected value="">
                    --- Select Model ---
                  </option>
                  {
                    showModels?<>
                  {
                    model.map(e => {
                      return <option value={e}>{e}</option>
                    })
                  }
                  </>
:
<option disabled value="">--- select brand first ---</option>
}
                </select>
                <input
                  required
                  type="number"
                  name="phone"
                  placeholder="Enter Your Number"
                  onChange={handleChange}
                />



                <select name="city" required onChange={handleChange}>
                  <option disabled selected  value="">
                    ---- select your city ----
                  </option>
                  <option value="Delhi">Delhi</option>
                  <option value="Noida">Noida</option>
                  <option value="Greater Noida">Greater Noida</option>
                  <option value="Gurugram">Gurugram</option>
                  <option value="Ghaziabad">Ghaziabad</option>
                  <option value="Faridabad">Faridabad</option>
                  <option value="Chandigarh">Chandigarh</option>
                </select>
                <button>
                  {" "}
                  <div className="pop-outin">BOOK NOW</div>
                </button>
              </form>
            </div>
          </div>


        </div>
        </div>

        {/* ----------- col-4 ------------ */}
        <div className="landing-page-header-bottom">
          Mechanic on Call : <Link title="contact" to="tel:9658124124"> +91-9658 124 124</Link>
        </div>
      </div>
    </div>
  );
};

export default LandingHeader;
