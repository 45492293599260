import React from 'react'

import del from "../../media/images/delhi.jpg"
import noi from "../../media/images/noida.jpg"
import gur from "../../media/images/gurgaon.jpg"
import far from "../../media/images/Faridabad.jpg"
import ghaz from "../../media/images/Ghaziabad.jpg"
import chd from "../../media/images/chandigarh.png"

const LandingLocation = () => {
  return (
    <div>
        <div className='landing-page-location'>
                    <h2 className='landing-page-location-heading'>We Provide Best <span>Bike Service</span> at <span>Home</span> by <span>Experts</span></h2>

                    <div className='landing-page-location-locations'>

                        {/* ---------- location 1---------- */}
                        <div className='landing-page-location-locations-col landing-page-location-locations-col-1 '>
                            <div className='landing-page-location-locations-col-icon'><img title="location"  src={del} alt='location icon' /></div>
                            <div className='landing-page-location-locations-col-text'>Bike Service <br />in <span>Delhi</span></div>
                        </div>


                        {/* ---------- location 2---------- */}
                        <div className='landing-page-location-locations-col'>
                            <div className='landing-page-location-locations-col-icon'><img title="location"  src={noi} alt='location icon' /></div>
                            <div className='landing-page-location-locations-col-text'>Bike Service <br />in <span>Noida</span></div>
                        </div>

                        {/* ---------- location 3---------- */}
                        <div className='landing-page-location-locations-col'>
                            <div className='landing-page-location-locations-col-icon'><img title="location"  src={noi} alt='location icon' /></div>
                            <div className='landing-page-location-locations-col-text'>Bike Service in<br /><span>Greater Noida</span></div>
                        </div>

                        {/* ---------- location 4---------- */}
                        <div className='landing-page-location-locations-col'>
                            <div className='landing-page-location-locations-col-icon'><img title="location"  src={gur} alt='location icon' /></div>
                            <div className='landing-page-location-locations-col-text'>Bike Service <br />in <span>Gurugram</span></div>
                        </div>

                        {/* ---------- location 5---------- */}
                        <div className='landing-page-location-locations-col'>
                            <div className='landing-page-location-locations-col-icon'><img title="location"  src={far} alt='location icon' /></div>
                            <div className='landing-page-location-locations-col-text'>Bike Service <br />in <span>Faridabad</span></div>
                        </div>

                        {/* ---------- location 6---------- */}
                        <div className='landing-page-location-locations-col'>
                            <div className='landing-page-location-locations-col-icon'><img title="location"  src={ghaz} alt='location icon' /></div>
                            <div className='landing-page-location-locations-col-text'>Bike Service <br />in <span>Ghaziabad</span></div>
                        </div>

                        {/* ---------- location 7 ---------- */}
                        <div className='landing-page-location-locations-col'>
                            <div className='landing-page-location-locations-col-icon'><img title="location"  src={chd} alt='location icon' /></div>
                            <div className='landing-page-location-locations-col-text'>Bike Service <br />in <span> Chandigarh</span></div>
                        </div>

                    </div>

                </div>
    </div>
  )
}

export default LandingLocation