import React from 'react'

const BengaluruLandingPrice = () => {
  return (
    <div>
        <div className='landing-page-price'>
                    <div className='landing-page-price-check'>
                        <div className='landing-page-price-check-heading'>General Bike Service <span>Check-list</span></div>
                        <div className='landing-page-price-check-list'>
                            <div>&#9989; Free Pick and Drop</div>
                            <div>&#9989; Free Wash and Polish</div>
                            <div>&#9989; Engine Oil Change (Price Extra)</div>
                            <div>&#9989; Oil Filter Clean (if Replace Charges)</div>
                            <div>&#9989; Air Filter Clean (if Replace Charges)</div>
                            <div>&#9989; Spark Plug Clean (if Replace Charges)</div>
                            <div>&#9989; Tightening of Screws, Bolts &amp; Nuts</div>
                            <div>&#9989; Average and Performance Check-up</div>
                            <div>&#9989; Brakes – Front &amp; Rear Adjust</div>
                            <div>&#9989; Driven Chain Basic Cleaning</div>
                            <div>&#9989; Carburettor Basic Check-up</div>
                            <div>&#9989; Minor Electrical Check-up</div>
                            <div>&#9989; Battery General Check-up</div>
                            <div>&#9989; Basic Engine Inspection</div>
                            <div>&#9989; Basic Fork Inspection</div>
                            <div>&#9989; Basic Hand Cleaning</div>
                        </div>
                    </div>
                    <div className='bengaluru-landing-page-price-button'>

                        <div className='landing-page-price-button-col'>
                            <div className='bengaluru-landing-page-price-button-content'><span>Bengaluru Bike Service Charges : Rs 649/- </span> <span><a href='#landingForm' className=''>BOOK NOW</a></span></div>
                            
                        </div>

                    </div>

                </div>
    </div>
  )
}

export default BengaluruLandingPrice