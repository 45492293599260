import React from 'react'

const LandingPrice = () => {
  return (
    <div>
        <div className='landing-page-price'>
                    <div className='landing-page-price-check'>
                        <h2 className='landing-page-price-check-heading'>General Bike Service <span>Check-list</span></h2>
                        <div className='landing-page-price-check-list'>
                            <div>&#9989; Engine Oil Change (Price Extra)</div>
                            <div>&#9989; Oil Filter Clean (if Replace Charges)</div>
                            <div>&#9989; Air Filter Clean (if Replace Charges)</div>
                            <div>&#9989; Spark Plug Clean (if Replace Charges)</div>
                            <div>&#9989; Tightening of Screws, Bolts &amp; Nuts</div>
                            <div>&#9989; Average and Performance Check-up</div>
                            <div>&#9989; Brakes – Front &amp; Rear Adjust</div>
                            <div>&#9989; Driven Chain Basic Cleaning</div>
                            <div>&#9989; Carburettor Basic Check-up</div>
                            <div>&#9989; Minor Electrical Check-up</div>
                            <div>&#9989; Battery General Check-up</div>
                            <div>&#9989; Basic Engine Inspection</div>
                            <div>&#9989; Basic Fork Inspection</div>
                            <div>&#9989; Basic Hand Cleaning</div>
                            <div>&#9989; Free Pick and Drop</div>
                        </div>
                    </div>
                    <div className='landing-page-price-button'>

                        <div className='landing-page-price-button-col'>
                            <div className='landing-page-price-button-col-heading-1'>Below 125 CC</div>
                            <div className='landing-page-price-button-col-heading-2'>Rs. 299 /-</div>
                            <a title="form link"  href='#landingForm' className='landing-page-price-button-col-button'>BOOK NOW</a>
                        </div>

                        <div className='landing-page-price-button-col'>
                            <div className='landing-page-price-button-col-heading-1'>125 CC to 199 CC</div>
                            <div className='landing-page-price-button-col-heading-2'>Rs. 399 /-</div>
                            <a title="form link"  href='#landingForm' className='landing-page-price-button-col-button'>BOOK NOW</a>
                        </div>

                        <div className='landing-page-price-button-col'>
                            <div className='landing-page-price-button-col-heading-1'>200 CC to 299 CC</div>
                            <div className='landing-page-price-button-col-heading-2'>Rs. 499 /-</div>
                            <a title="form link"  href='#landingForm' className='landing-page-price-button-col-button'>BOOK NOW</a>
                        </div>

                        <div className='landing-page-price-button-col'>
                            <div className='landing-page-price-button-col-heading-1'>300 CC to 350 CC</div>
                            <div className='landing-page-price-button-col-heading-2'>Rs. 599 /-</div>
                            <a title="form link"  href='#landingForm' className='landing-page-price-button-col-button'>BOOK NOW</a>
                        </div>

                        <div className='landing-page-price-button-col'>
                            <div className='landing-page-price-button-col-heading-1'>Above 350 CC</div>
                            <div className='landing-page-price-button-col-heading-2'>Rs. 999 /-</div>
                            <a title="form link"  href='#landingForm' className='landing-page-price-button-col-button'>BOOK NOW</a>
                        </div>

                    </div>

                </div>
    </div>
  )
}

export default LandingPrice