import React from 'react'
import { Link } from 'react-router-dom'

const ErrorPage = () => {
  return (
    <div>
        <div className="error-page">
            <h1>ERROR</h1>
            <h2>this page is not valid</h2>
            <h2><Link to="/">go to home</Link></h2>
        </div>
    </div>
  )
}

export default ErrorPage